import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45initialize_45stores_45global from "/app/src/middleware/01.initialize-stores.global.ts";
import _02_45welcome_45screen_45redirect_45global from "/app/src/middleware/02.welcome-screen-redirect.global.ts";
import _03_45update_45current_45locale_45global from "/app/src/middleware/03.update-current-locale.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45initialize_45stores_45global,
  _02_45welcome_45screen_45redirect_45global,
  _03_45update_45current_45locale_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "auth-forbidden": () => import("/app/src/middleware/auth-forbidden.ts"),
  "auth-required": () => import("/app/src/middleware/auth-required.ts"),
  "organization-access": () => import("/app/src/middleware/organization-access.ts")
}